export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66')
];

export const server_loads = [0,2,3];

export const dictionary = {
		"/": [~6],
		"/auth0/auth": [~28],
		"/auth0/logout": [~29],
		"/auth/error": [24],
		"/auth/login": [~25],
		"/auth/logout": [~26],
		"/auth/token/[token]": [~27],
		"/(cp)/cp": [~7,[2]],
		"/(cp)/cp/agent-view/conversations/[conversationId]": [~9,[2]],
		"/(cp)/cp/agent-view/[agentId]": [~8,[2]],
		"/(cp)/cp/application/[applicationId]": [~10,[2]],
		"/(cp)/cp/knowledge": [~11,[2]],
		"/(cp)/cp/qstar": [~12,[2,3]],
		"/(cp)/cp/qstar/persona_list/[id]": [~13,[2,3]],
		"/(cp)/cp/quality": [~14,[2]],
		"/(cp)/cp/requests": [~15,[2]],
		"/(cp)/cp/settings": [~16,[2]],
		"/(cp)/cp/smart-agents": [17,[2]],
		"/(cp)/cp/testing-suite": [~18,[2]],
		"/(cp)/cp/wave/[waveId]": [~19,[2]],
		"/itops/administrate-ta": [~30],
		"/itops/client-accounts": [~31],
		"/itops/client-accounts/add": [~40],
		"/itops/client-accounts/edit/[clientAccountId]": [~41],
		"/itops/client-accounts/[clientAccountId]": [~32],
		"/itops/client-accounts/[clientAccountId]/lob/add": [~34],
		"/itops/client-accounts/[clientAccountId]/lob/edit/[lobId]": [~35],
		"/itops/client-accounts/[clientAccountId]/lob/[lobId]": [~33],
		"/itops/client-accounts/[clientAccountId]/wave/add": [~39],
		"/itops/client-accounts/[clientAccountId]/wave/[waveId]": [~36],
		"/itops/client-accounts/[clientAccountId]/wave/[waveId]/edit": [~37],
		"/itops/client-accounts/[clientAccountId]/wave/[waveId]/view": [~38],
		"/itops/dashboard": [~42],
		"/itops/developers/deployment-frequency": [~43,[4]],
		"/itops/developers/feature-flags": [~44,[4]],
		"/itops/developers/test-coverage": [~45,[4]],
		"/itops/flows": [~46],
		"/itops/jobs": [~47],
		"/itops/roles/add": [~48],
		"/itops/users/list": [~50],
		"/itops/users/[userId]": [~49],
		"/modals": [51,[5]],
		"/modals/add-agent": [52,[5]],
		"/modals/congratulatory": [53,[5]],
		"/modals/request-agent": [54,[5]],
		"/ta": [~55],
		"/ta/applications": [~57],
		"/ta/application/[applicationId]": [~56],
		"/ta/dashboard": [~58],
		"/ta/dashboard/job-editor": [~59],
		"/ta/dashboard/job-editor/[jobId]": [~60],
		"/ta/emails": [~61],
		"/ta/inoffice": [~62],
		"/ta/interviews": [~63],
		"/ta/jobs": [~64],
		"/ta/wave/[waveId]": [~65],
		"/xobot": [~66],
		"/[clientId]/interview/[appId]": [~20],
		"/[clientId]/poc-editor": [~21],
		"/[clientId]/talent/[lobId]": [~22],
		"/[clientId]/talent/[lobId]/[waveId]": [~23]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';